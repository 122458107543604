import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import CSSModules from 'react-css-modules';
import { Link } from 'gatsby';

import { constructLink } from '../../../../helper';
import styles from './styles.module.css';
import '../../../../carousel.css';
import Image from '../../../Image';

class CarouselLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    let crop = true;
    if (
      props.section
      && props.section.styles
      && props.section.styles.crop === false
    ) ({ crop } = props.section.styles);

    this.state = {
      section: props.section,
      crop,
    };

  }

  render() {

    const colorBlack = {
      color: '#000000',
    };

    const cards = [];

    this.state.section.data.map((item, index) => {

      if (item.type === 'IMAGES/IMAGE') {

        const card = (
          <div
            key={`${this.state.section._id}_${this.state.section.data[index].content.src}_${index}`}
            className={`${styles.carouselWrapper}`}
          >
            <div className={`${styles.carouselImageWrapper}`}>
              <Image
                id={this.state.section.data[index].content.id}
                url={this.state.section.data[index].content.src}
                alt={this.state.section.data[index].content.alt}
                imageClass={`carouselImg${this.state.crop === false ? 'NoCrop' : ''}`}
                images={this.props.images}
              />
              {
                (
                  this.state.section.styles.alt === true
                  || this.state.section.styles
                    .description === true
                )
                && (item.content.alt || item.content.description)
                && (
                  <div className={`${styles.imageTextWrapper}`} style={this.props.boxStyle}>
                    {
                      this.state.section.styles.alt === true
                      && (
                        <div className={`${styles.imageAlt}`}>
                          <h2 style={colorBlack}>
                            {
                              item.content.alt
                            }
                          </h2>
                        </div>
                      )
                    }
                    {
                      this.state.section.styles
                        .description === true
                      && (
                        <div className={`${styles.imageText}`}>
                          <span style={colorBlack}>
                            {
                              item.content.description
                            }
                          </span>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        );

        if (item.content.linkObj && (item.content.linkObj.src || item.content.linkObj.type)) {

          const { link, type } = constructLink(
            item.content.linkObj.type ? item.content.linkObj : item.content.linkObj.src,
            this.props.pagePathList,
            this.props.articlePathList,
            this.props.filePathList,
          );

          const isLink = link !== undefined;
          const isLocal = isLink && type !== 'EXTERNAL' && type !== 'FILE';
          let tab;
          if (!isLocal) tab = link && link.startsWith('http') ? '_blank' : '_self';
          else {

            if (typeof item.content.linkObj.type) tab = item.content.linkObj.openLinkInNewTab ? '_blank' : '_self';
            else tab = item.content.linkObj.newTab && item.content.linkObj.src.startsWith('http') ? '_blank' : '_self';

          }

          let cardLinked;
          if (type !== 'EXTERNAL' && type !== 'FILE' && isLink && tab !== '_blank') {

            cardLinked = (
              <Link to={link} key={`${this.props.section._id}_CarouselLink_${index}`}>
                { card }
              </Link>
            );

          } else if (isLink && (!isLocal || tab === '_blank')) {

            cardLinked = (
              <a
                target={tab ? '_blank' : '_self'}
                href={link}
                key={`${this.props.section._id}_CarouselLink_${index}`}
                rel={tab ? 'noopener noreferrer' : undefined}
              >
                { card }
              </a>
            );

          } else cardLinked = card;

          cards.push(cardLinked);

        } else {

          cards.push(card);

        }

      }

      return null;

    });

    let { arrows } = this.state.section.styles;
    if (typeof document !== 'undefined') {

      if (document.documentElement.clientWidth <= 576) {

        arrows = false;

      }

    }

    return (
      <div className={`${this.props.full ? '' : 'container'} ${styles[`carouselContainer${this.props.full}`]}` }>
        <div className={`row ${this.props.full ? styles.noMargin : ''}`}>
          <div className={`col-12 ${styles.carouselCol}`}>
            <Carousel
              showThumbs={false}
              showArrows={arrows}
              showIndicators={this.state.section.styles.indicators}
              showStatus={false}
              autoPlay={this.state.section.styles.autoplay}
              infiniteLoop={this.state.section.styles.infinite}
              interval={this.state.section.styles.interval}
              axis={this.state.section.styles.transition}
            >
              { cards }
            </Carousel>
          </div>
        </div>
      </div>
    );

  }

}

CarouselLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  full: PropTypes.string,
};

export default CSSModules(CarouselLayout, styles, { allowMultiple: true });
