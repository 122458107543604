import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import { Link } from 'gatsby';

import ButtonEditor from '../../../../EditorPanel/ButtonEditor';
import Image from '../../../../Image';
import styles from './styles.module.css';

class SubLinks extends React.Component {

  constructor(props) {

    super(props);

    this.createLink = this.createLink.bind(this);

  }

  createLink(rawLink, i) {

    let style = { color: this.props.color };
    let styleNames = `${styles.subPageLinkColor} ${styles.subPageLink}`;
    if (this.props.isBurger) {

      style = { fontSize: '1rem' };
      styleNames = styles.burgerItemSmall;

    }

    let link;
    let targetValue = rawLink.openLinkInNewTab ? '_blank' : '_self';

    let btn;
    if (rawLink.format === 'BUTTON') {

      btn = (
        <ButtonEditor
          themeData={this.props.themeData}
          button="Button"
          data={rawLink.formatData}
          text={rawLink.text || rawLink.name || rawLink.path}
          buttons={this.props.buttons}
        />
      );

    }

    const linkContent = (
      <li className={styleNames} style={style}>
        {
          rawLink.format === 'IMAGE' && rawLink.formatData && rawLink.formatData.id
          && (
            (
              <Image
                id={rawLink.formatData.id}
                alt={`Navbar_Image_${rawLink.formatData.id}`}
                imageClass="navbarImage"
                imageStyle="navbarImage"
              />
            )
          )
        }
        { btn }
        {
          rawLink.format === 'ICON' && rawLink.formatData
          && (
            <i className={`entypo ${rawLink.formatData.class}`} style={{ fontSize: rawLink.formatData.size, color: rawLink.formatData.color }} aria-hidden />
          )
        }
        {
          rawLink.format === 'TEXT'
          && (
            (rawLink.type === 'EXTERNAL' && (rawLink.text || rawLink.path))
            || ((rawLink.type === 'ARTICLE' || rawLink.type === 'FILE') && (rawLink.text || rawLink.name))
            || rawLink.name
          )
        }
      </li>
    );

    if (rawLink.type === 'FILE') targetValue = '_blank';

    if (rawLink.type === 'EXTERNAL' || rawLink.type === 'FILE' || targetValue === '_blank') {

      link = (
        <a
          key={`Sublink_${rawLink.name}_${i}`}
          href={rawLink.path}
          className={`${styles[`menu${rawLink.children && rawLink.children.length > 0 ? 'Main' : ''}Link`]} ${styles.Link}`}
          style={{
            ...{ position: 'relative' },
            color: this.props.color,
            marginLeft: rawLink.format === 'ICON' ? '5px' : undefined,
            marginRight: rawLink.format === 'ICON' ? '5px' : undefined,
          }}
          target={targetValue}
          rel="noopener noreferrer"
        >
          { linkContent }
        </a>
      );

    } else {

      link = (
        <Link
          key={`Sublink_${rawLink.name}_${i}`}
          to={rawLink.path || '/'}
          state={rawLink.type === 'SECTION' ? { scrollToSection: rawLink.id } : undefined}
          className={`${styles[`menu${rawLink.children && rawLink.children.length > 0 ? 'Main' : ''}Link`]} ${styles.Link}`}
          style={{
            ...{ position: 'relative' },
            color: this.props.color,
            marginLeft: rawLink.format === 'ICON' ? '5px' : undefined,
            marginRight: rawLink.format === 'ICON' ? '5px' : undefined,
          }}
          activeStyle={rawLink.type !== 'SECTION' ? this.props.activeStyle : undefined}
          onClick={this.props.onLinkClick}
        >
          {linkContent}
        </Link>
      );

    }

    return link;

  }

  render() {

    let top = { top: '25px' };
    if (this.props.themeData.typography.navigation.fontSize === 'Small') top = { top: '15px' };
    else if (this.props.themeData.typography.navigation.fontSize === 'Normal') top = { top: '20px' };

    const element = this.props.isBurger
      ? (
        <span>
          { this.props.links.map((lnk, i) => this.createLink(lnk, i)) }
        </span>
      )
      : (
        <ul className={styles.navbarSubLink} style={{ ...this.props.subColor, ...top }}>
          { this.props.links.map((lnk, i) => this.createLink(lnk, i)) }
        </ul>
      );

    return element;

  }

}

SubLinks.propTypes = {
  color: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({})),
  isBurger: PropTypes.bool,
  activeColor: PropTypes.string,
  onLinkClick: PropTypes.func,
  subColor: PropTypes.shape({}),
};

export default CSSModules(SubLinks, styles, { allowMultiple: true });
